import { Company, CompanyAccessLevel, Profile } from '@agdir/domain';

export enum InvitationStatus {
	EXPIRED = 1,
	PENDING = 2,
	ACCEPTED = 3,
	DELETED = 4,
	REVOKED = 5,
	DECLINED = 6,
}

export const InvitationStatusToText = new Map<InvitationStatus, string>([
	[InvitationStatus.EXPIRED, 'expired'],
	[InvitationStatus.PENDING, 'pending'],
	[InvitationStatus.ACCEPTED, 'accepted'],
	[InvitationStatus.DELETED, 'deleted'],
	[InvitationStatus.REVOKED, 'revoked'],
	[InvitationStatus.DECLINED, 'declined'],
]);

export type InvitationCode = string;

export interface InvitationI18n {
	codeInvalid: string;
	codeNotFound: {
		action: string;
		message: string;
	};
	invitationCode: string;
	inviteOthers: string;
	inviteOthersModalTitle: string;
	invitedYouToJoinAnOrganization: string;
	noInvitationsToShow: string;
	pleaseEnterCodeBelow: string;
	removeShareModal: {
		noButton: string;
		text: string;
		title: string;
		yesButton: string;
	};
	showAllToggle: string;
	status: {
		acceptInvite: string;
		accepted: string;
		acceptedAt: string;
		createdAt: string;
		declineInvite: string;
		declined: string;
		declinedAt: string;
		deleteInvite: string;
		deleted: string;
		deletedAt: string;
		expired: string;
		expiredAt: string;
		pending: string;
		pendingAt: string;
		revokeInvite: string;
		revoked: string;
		revokedAt: string;
	};
	title: string;
	tryDifferentCode: string;
	yourEmail: string;
	yourName: string;
	yourPhone: string;
	yourSurname: string;
}

export interface Invitation {
	status: InvitationStatus;
	code: InvitationCode | null;
	changedAt: Date | null;
	createdAt: Date | null;
	expiresAt: Date | null;
	inviter: Profile | null;
	invitee: Partial<Profile>;
	company: Company;
	permission: CompanyAccessLevel | null;
	forceCreateInvitee: boolean | null;
	sendSms: boolean | null;
	sendEmail: boolean | null;
	acceptInvitation: boolean | null;
}

export interface AnonymousInvitation {
	code: InvitationCode | null;
	userExists: boolean;
	invitee: Profile;
	inviter: string;
	companyName: string;
}

export interface InvitationWithProgress extends Invitation {
	phoneNumberValidated: null;
}
